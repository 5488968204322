import React from 'react';
export const Testimonials = () =>
  <section id="testimonials">
    <div className="text-container">
      <div className="row">
        <div className="two columns header-col">
        </div>
        <div className="ten columns flex-container">
          <div className="flexslider">
            <ul className="slides">
            </ul>
          </div> {/* div.flexslider ends */}
        </div> {/* div.flex-container ends */}
      </div> {/* row ends */}
    </div>  {/* text-container ends */}
  </section> /* Testimonials Section End*/
