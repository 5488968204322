import React from "react";

import STRINGS from "../../localization";

export const About = () => (
  <section id="about">
    <div className="row">
      <div className="three columns">
        <img className="profile-pic" src="assets/images/profilepic.jpg" alt="profile picture" />
      </div>
      <div className="nine columns main-col">
        <h2>{STRINGS.ABOUT_ME_TITLE}</h2>
        <p>{STRINGS.ABOUT_ME_CONTENT}</p>
        <div className="row">
        </div>{" "}
        {/* end row */}
      </div>{" "}
      {/* end .main-col */}
    </div>
  </section>
); /* About Section End*/
