export default {
  //General,
  JANUARY: `January`,
  FEBRUARY: `February`,
  SEPTEMBER: `September`,
  APRIL: `April`,
  MARCH: `March`,
  MAY: `May`,
  JUNE: `June`,
  JULY: `July`,
  AUGUST: `August`,
  DECEMBER: `December`,
  TWO_THOUSAND_TWENTY: `2020`,
  TWO_THOUSAND_NINETEEN: `2019`,
  TWO_THOUSAND_EIGHTEEN: `2018`,
  TWO_THOUSAND_THIRTEEN: `2013`,
  TWO_THOHUSAND_SEVENTEEN: `2017`,
  PRESENT: `Present`,
  FULL_STACK_ENGINEER: `Fullstack software developer`,
  CO_FOUNDER: "Co-founder",
  INTERN: `Intern`,
  PART_TIME_DEVELOPER: "Software developer",

  //Tools
  PHOTOSHOP: `Photoshop`,
  BLENDER: `Blender`,
  GIT: `Git`,
  DOCKER: `Docker`,
  VISUAL_PARADIGM: `Visual Paradigm`,
  AZURE: "Azure",
  SLACK: "Slack",

  //Introduction
  MY_NAME: "Shensly Hooplot",
  GENERAL_INTRODUCTION: `Hi there, I'm Shensly`,
  DETAILED_INTROCUCTION_1: `I'm a `,
  DETAILED_INTROCUCTION_2: `from`,
  DETAILED_INTROCUCTION_3: `I dig building everything from small business sites to rich web and software applications`,
  DETAILED_INTROCUCTION_4: `Let's`,
  DETAILED_INTROCUCTION_5: `start scrolling`,
  DETAILED_INTROCUCTION_6: `and learn more`,
  DETAILED_INTROCUCTION_7: `about me`,
  DETAILED_INTROCUCTION_8: `and currently living in`,
  DETAILED_INTROCUCTION_9: `and I'm currently open to involve myself in some awesome projects!`,

  THE_NETHERLANDS: `The Netherlands`,
  KOREA: `Korea`,

  HEY_YOU_WHAT_IS_YOUR_PROFFESION_QUESTIONMARK: "A Software Developer",

  //Mobile
  MOBILE_SHOW_NAVIGATION: "Show navigation",
  MOBILE_HIDE_NAVIGATION: "Hide navigation",

  //MENU
  MENU_HOME: "Home",
  MENU_ABOUT: "About",
  MENU_RESUME: "Resume",
  MENU_WORKS: "Works",
  MENU_TESTIMONIALS: "Testimonials",
  MENU_CONTACT: "Contact",

  //About
  ABOUT_ME_TITLE: "About me",
  ABOUT_ME_CONTENT: `I'm a hands-on and a passionate technologist at heart, who loves connecting the dots between people and software to engineer business value. 
  It has been a hot minute in this industry, but it's ever changing, so I like to keep myself on track by developing and incorporating the latest tech in my work.
  `,

  //Contact
  GET_IN_TOUCH: `Get in touch.`,
  CONTACT_LEAD: `If you like to get in touch, please do not hesitate to contact me below! I will try to get back to you as soon as possible.`,
  CONTACT_NAME: `Name`,
  CONTACT_EMAIL: `Email`,
  CONTACT_SUBJECT: `Subject`,
  CONTACT_MESSAGE: `Message`,
  CONTACT_SUBMIT: `Submit`,
  CONTACT_MESSAGE_SUCCESS: `Your message was sent, thank you!`,
  CONTSACT_MESSAGE_FAIL: `Your messsage could not be sent at this time, try again later`,
  ADDRESS_AND_PHONE: `Address and Phone`,
  CONTACT_PHONE: `Phone`,

  //Validation Errors
  ERRORS_REQUIRED: `This field is required`,
  ERRORS_SUBJECT_MIN_LENGTH: `Subject should be at least 5 characters`,
  ERRORS_MESSAGE_MIN_LENGTH: `Message should be at least 25 characters`,
  ERRORS_INVALID_EMAIL: `Invalid email`,
};
