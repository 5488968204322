import React from 'react';
import STRINGS from '../../localization';

import ContactForm from './Forms/ContactForm'

export const Contact = () =>
  <section id="contact">
    <div className="row section-head">
      <div className="two columns header-col">
        <h1><span>{STRINGS.GET_IN_TOUCH}</span></h1>
      </div>
      <div className="ten columns">
        <p className="lead">{STRINGS.CONTACT_LEAD}</p>
      </div>
    </div>
    <div className="row">
      <div className="eight columns">
        <ContactForm />
      </div>
    </div>
  </section> /* Contact Section End*/
